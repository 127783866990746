// @flow
import { formatDefaultDate } from '@dpdgroupuk/fmx-ui/utils/date';

import { changeCollectionOption } from '../../../../store/orm/Collection/actions';
import type { ChangeCollectionOptionOptions } from '../../../../apis';
import type { AuthorizedCollection } from '../../../../types/Collection';
import { getNotificationDetails } from '../../../../models/collection';

type DataProps = {
  day: string,
  notificationDetails?: Object,
};

export const changeCollectionPreferenceDay = (
  collection: AuthorizedCollection,
  { day, notificationDetails }: DataProps,
  options: ChangeCollectionOptionOptions
) =>
  changeCollectionOption(
    collection.collectionCode,
    {
      collectionDate: formatDefaultDate(day),
      notificationDetails:
        notificationDetails || getNotificationDetails(collection),
    },
    options
  );
